import React from "react";
import styled from "styled-components";
import logo from "../../../assets/img/logo_white.png";
import logo1 from "../../../assets/img/bd_logo_circle_white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icons from "../../../services/fontawesome";
import SocialIcon from "./SocialIcon";
import GithubLogo from "../../../assets/img/github-logo.png";

const ContactSection = styled.div`
  background-color: #27343e;
  color: #ccc;
  padding: 50px;

  @media screen and (max-width: 992px) {    
    .col {
      width: 40% !important;
      padding: 20px !important;
    }
  }
  
  
  @media screen and (max-width: 688px) {
    .col {
      width: 90% !important;
      padding: 15px 0 !important;
    }
  }

  .inner {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    max-width: 1170px;
    font-size: 0.9rem;
    flex-wrap: wrap;
  }

  .col {
    width: 20%;
    padding: 0 15px;

    .col-content {
      font-size: 0.8rem;

      div {
        padding-top: 5px;
        display: flex;

        span {
          padding-left: 7px;
        }

        svg {
          font-size: 1.1rem;
        }
      }

      a {
        color: #ccc;
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }

      .socials {
        display: flex;
        padding: 0;
        margin-top: 10px;
      }
    }

    .github-logo {
      height: 25px;
      margin-bottom: 5px;
    }

    .github-link {
      text-decoration: none;
      color: #fff;
    }
  }

  
  .logo {
    margin-bottom: 10px;
    display: flex;
  }

  .logo img {
    height: 30px;
  }

  .about {
    font-size: 0.8rem;
  }
  hr {
    width: 30px;
    margin: 5px 0;
    border-top: 1px solid #fff;
  }
  h4 {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.4px;
  }
`;

function Contact() {
  return (
    <ContactSection id="contact">
      <div className="inner">
        <div className="col">
          <div className="logo">
            <img src={logo1} alt="logo" />
            <img src={logo} alt="logo" />
          </div>
          <div className="about">
            Konzeption und Umsetzung von digitalen Strategien und
            Web-Applikationen mit dem Fokus auf Behavioral Design und User
            Experience
          </div>
        </div>
        <div className="col">
          <h4>Netzwerkpartner</h4>
          <hr />
          <div className="col-content">
          <a href="https://netzkundig.com/">Netzkundig</a>
          </div>
        </div>
        <div className="col">
          <img className="github-logo" src={GithubLogo} alt="GitHub" />
          <div className="col-content">
            Einen Einblick in weitere Projekte, Ideen und Code gibt mein
            GitHub-Profil:
          </div>
          <a
            href="https://github.com/bnidev"
            className="github-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            github.com/bnidev
          </a>
        </div>
        <div className="col">
          <h4>Kontakt</h4>
          <hr />
          <div className="col-content">
            <div className="contact-mail">
              <a href="mailto:bd@benjamindiener.com">
                <FontAwesomeIcon icon={Icons.email} />{" "}
                <span>bd@benjamindiener.com</span>
              </a>
            </div>
           
            <ul className="socials">
            <SocialIcon icon={Icons.facebook} color="#1877f2" url="https://www.facebook.com/benjamindiener"/>
            <SocialIcon icon={Icons.xing} color="#026466" url="https://www.xing.com/profile/Benjamin_Diener3"/>
            <SocialIcon icon={Icons.skype} color="#00aff0" url="skype:mousesports-benni?chat"/>
            </ul>
          </div>
        </div>
      </div>
    </ContactSection>
  );
}

export default Contact;
