import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Selector from "./Selector";
import ClientLogo from "./ClientLogo";
import ClientData from "./ClientData";

const SelectorList = [
  {
    name: "Alle",
    filter: "alle",
  },
  { name: "Web Development", filter: "webdev" },
  { name: "Consulting", filter: "consulting" },
  { name: "Social Media", filter: "social" },
  { name: "Content Management", filter: "content" },
];

const ClientSection = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: center;
  //background-color: #f9fbfc;
  background-color: #e8f0f4;

  @media screen and (max-width: 992px) {
    p {
      width: 90% !important;
    }

    .clientList {
      width: 90% !important;
    }
    
    > h2 {
      font-size: 2.7rem !important;
    }
  }
  

  > h2 {
    font-size: 3rem;
    color: #303e50;
    padding: 15px;
  }
  > h4 {
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    padding: 0 15px;
  }
  > hr {
    width: 60px;
    margin: 30px auto;
    border-top: 2px solid #303e50;
  }
  > p {
    color: #6c747d;
    margin: 0 auto;
    font-size: 1.1rem;
    line-height: 1.4;
    padding-bottom: 40px;
    width: 40%;
    text-align: center;
  }

  .clientSelect {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 15px;
  }

  .clientList {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    justify-content: center;
    margin: 0 auto;
    padding-top: 20px;

       
   
  }
`;

function Clients() {
  const [filter, setFilter] = useState("alle");
  const [clients, setClients] = useState([]);

  useEffect(() => {
    setClients(ClientData);
  }, []);

  useEffect(() => {
    setClients([]);

    const filtered = ClientData.map((p) => ({
      ...p,
      filtered: p.tag.includes(filter),
    }));
    setClients(filtered);
  }, [filter]);

  return (
    <ClientSection id="clients">
      <h4>Wem ich mit meiner Arbeit bereits helfen konnte</h4>
      <h2>Digitale Strategien erfolgreich umgesetzt</h2>
      <hr />
      <p>
        Von meiner Arbeit profitieren Einzelpersonen und Unternehmen aus dem
        deutschsprachigen Raum. Sie kommen aus den verschiedensten Branchen,
        darunter Musik, Theater, Journalismus, Handwerk, IT und Gastronomie. Für
        sie habe ich Websites konzipiert und entwickelt, Content-Strategien
        entworfen, Social Media Kanäle betreut und sie bei ihren digitalen
        Entscheidungen beraten.
      </p>
      <div className="clientSelect">
        {SelectorList.map((selector, i) => (
          <Selector
            key={selector.filter}
            name={selector.name}
            handleClick={() => setFilter(selector.filter)}
            active={filter === selector.filter}
          />
        ))}
      </div>
      <div className="clientList">
        {clients.map((client) =>
          client.filtered === true ? (
            <ClientLogo
              key={client.name}
              name={client.name}
              img={client.logo}
            />
          ) : (
            ""
          )
        )}
      </div>
    </ClientSection>
  );
}

export default Clients;
