import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import App from "./components/App";
import store from "./services/redux/store";
import { Provider } from "react-redux";
import {BrowserRouter} from "react-router-dom"

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
    <React.StrictMode>
      <App />
    </React.StrictMode>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

