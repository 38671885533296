import React from "react";
import "../../css/Header.css";
import logo from "../../assets/img/logo_white.png";
import logo1 from "../../assets/img/bd_logo_circle_white.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icons from "../../services/fontawesome";
import { useSelector, useDispatch } from "react-redux";
import { OpenMenu, CloseMenu } from "../../services/redux/actions";


function Header() {
  const isMenuOpen = useSelector((state) => state.isMenuOpen);
  const dispatch = useDispatch();

  const toggleMenu = () => {
    if (isMenuOpen) {
      document.body.style.overflow = "";
      dispatch(CloseMenu());
    } else {
      document.body.style.overflow = "hidden";
      dispatch(OpenMenu());
    }
  };

  return (
    <div className="header">
      <div className="header__wrapper">      
        <div className="header__left">          
            <img src={logo1} alt="logo" />
            <img src={logo} alt="logo" />          
        </div>        
        <div className="header__right">
          <FontAwesomeIcon
            icon={isMenuOpen ? Icons.close : Icons.burger}
            onClick={() => toggleMenu()}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
