const ClientData = [
  {
    name: "Amra Dedic Photography",
    logo:
      "http://dev.benjamindiener.com/wp-content/uploads/Logo-Amra-Dedic.jpg",
    url: "",
    tag: ["alle", "consulting"],
  },
  {
    name: "Netzkundig",
    logo:
      "http://dev.benjamindiener.com/wp-content/uploads/Logo-Netzkundig.jpg",
    url: "",
    tag: ["alle", "webdev", "social"],
  },
  {
    name: "Corinna Fehr",
    logo:
      "http://dev.benjamindiener.com/wp-content/uploads/Logo-Corinna-Fehr.jpg",
    url: "",
    tag: ["alle", "webdev"],
  },
  {
    name: "Premium Speakers",
    logo:
      "http://dev.benjamindiener.com/wp-content/uploads/Logo-Premium-Speakers.jpg",
    url: "",
    tag: ["alle", "social"],
  },
  {
    name: "Digital Business Transformation",
    logo:
      "http://dev.benjamindiener.com/wp-content/uploads/Logo-Digital-Business-Transformation.jpg",
    url: "",
    tag: ["alle", "social", "webdev"],
  },
  {
    name: "Alpensymposium",
    logo:
      "http://dev.benjamindiener.com/wp-content/uploads/Logo-Alpensymposium.jpg",
    url: "",
    tag: ["alle", "social"],
  },
  {
    name: "Horasis",
    logo: "http://dev.benjamindiener.com/wp-content/uploads/Logo-Horasis.jpg",
    url: "",
    tag: ["alle", "social"],
  },
  {
    name: "Urban's",
    logo: "http://dev.benjamindiener.com/wp-content/uploads/Logo-Urbans-1.jpg",
    url: "",
    tag: ["alle", "consulting"],
  },
  {
    name: "Car & Bike",
    logo:
      "http://dev.benjamindiener.com/wp-content/uploads/Logo-Car-und-Bike.jpg",
    url: "",
    tag: ["alle", "webdev"],
  },
  {
    name: "Aluminium-Verband Schweiz",
    logo: "http://dev.benjamindiener.com/wp-content/uploads/Logo-Alu.jpg",
    url: "",
    tag: ["alle", "web"],
  },
  {
    name: "Digital Economic Forum",
    logo:
      "http://dev.benjamindiener.com/wp-content/uploads/Logo-Digital-Economic-Forum-1.jpg",
    url: "",
    tag: ["alle", "social"],
  },
  {
    name: "FehrAdvice",
    logo:
      "http://dev.benjamindiener.com/wp-content/uploads/Logo-FehrAdvice.jpg",
    url: "",
    tag: ["alle", "social", "content"],
  },
  {
    name: "Giesserei-Verband der Schweiz",
    logo: "http://dev.benjamindiener.com/wp-content/uploads/Logo-GVB.jpg",
    url: "",
    tag: ["alle", "webdev"],
  },
  {
    name: "Laura Friedrich Tejero",
    logo:
      "http://dev.benjamindiener.com/wp-content/uploads/Logo-Laura-Friedrich-Tejero.jpg",
    url: "",
    tag: ["alle", "consulting", "webdev"],
  },
  {
    name: "Nathan Trent",
    logo:
      "http://dev.benjamindiener.com/wp-content/uploads/Logo-Nathan-Trent.jpg",
    url: "",
    tag: ["alle", "consulting", "webdev", "content"],
  },
  {
    name: "Reportagen",
    logo:
      "http://dev.benjamindiener.com/wp-content/uploads/Logo-Reportagen.jpg",
    url: "",
    tag: ["alle", "webdev", "content"],
  },
  {
    name: "SafeSwissCloud",
    logo:
      "http://dev.benjamindiener.com/wp-content/uploads/Logo-SafeSwissCloud.jpg",
    url: "",
    tag: ["alle", "webdev", "social"],
  },
  {
    name: "Syncom",
    logo: "http://dev.benjamindiener.com/wp-content/uploads/Logo-Syncom.jpg",
    url: "",
    tag: ["alle", "webdev"],
  },
  {
    name: "Zurich Behavioral Econics Network",
    logo: "http://dev.benjamindiener.com/wp-content/uploads/Logo-ZBEN.jpg",
    url: "",
    tag: ["alle", "webdev"],
  },
  {
    name: "Lenzelot",
    logo:
      "http://dev.benjamindiener.com/wp-content/uploads/Logo-SoundDesignStudiosLenzelot.jpg",
    url: "",
    tag: ["alle", "consulting", "social"],
  },
  {
    name: "Whiskeybox Switzerland",
    logo:
      "http://dev.benjamindiener.com/wp-content/uploads/Logo-Whiskybox-1.jpg",
    url: "",
    tag: ["alle", "webdev"],
  },
];

export default ClientData;
