import React from 'react'
import "../../../css/Impressum.css"
import { CloseImpressum } from '../../../services/redux/actions'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icons from "../../../services/fontawesome";

function Impressum() {
    const dispatch = useDispatch()

    const closeImpressum = () => {
        document.body.style.overflow = "";
        dispatch(CloseImpressum())
    }
    return (
        <div className="impressum" onClick={() => closeImpressum()}>
            <div className="impressum__content">
                <div className="impressum__header">
                    <h2 className="impressum__title">Impressum, Datenschutz & Haftungsausschluss</h2>
                    <FontAwesomeIcon
            icon={Icons.close}
            onClick={() => closeImpressum()}
          />
                </div>
                <div className="impressum__body">
                    <div className="impressum__section">
                        <h3>Verantwortlich für den Seiteninhalt</h3>                        
                        <p>Benjamin Diener<br/>
                        Mariahilfer Straße 101, Top 21<br/>
                        A-1060 Wien<br/>
E-Mail: bd@benjamindiener.com</p>
                    </div>
                    <div className="impressum__section">
                        <h3>Datenschutz</h3>
                        <p>Auf dieser Website werden keine personenbezogenen Daten erhoben.</p>
                    </div>
                    <div className="impressum__section">
                        <h3>Haftung für Inhalte dieser Webseite</h3>
                        <p>Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen bereitzustellen. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite übernehmen, speziell für jene die seitens Dritter bereitgestellt wird. Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitte wir Sie uns umgehend zu kontaktieren, Sie finden die Kontaktdaten im Impressum.</p>
                    </div>
                    <div className="impressum__section">
                        <h3>Haftung für Links auf dieser Webseite</h3>
                        <p>Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Wenn Ihnen rechtswidrige Links auf unserer Webseite auffallen, bitte wir Sie uns zu kontaktieren, Sie finden die Kontaktdaten im Impressum.</p>
                    </div>
                    <div className="impressum__section">
                        <h3>Urheberrechtshinweis</h3>
                        <p>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Falls notwendig, werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.</p>
                    </div>
                    <div className="impressum__section">
                        <h3>Bildernachweis</h3>
                        <p>Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.</p>
                        <p>Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:
                            <ul><li>Amra Dedic</li></ul>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Impressum
