import React from "react";
import styled from "styled-components";

const SelectButton = styled.div`
  border: 1px solid ${({ active }) => (active ? "#c9d0d6" : "transparent")};
  color: #acaeb0;
  padding: 6px 10px;
  margin: 5px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1.4px;
  font-size: 0.8rem;

  &:hover {
    border-bottom: 1px solid #c9d0d6;
  }
`;

function Selector({ name, handleClick, active }) {
  return (
    <SelectButton active={active} onClick={handleClick}>
      {name}
    </SelectButton>
  );
}

export default Selector;
