import React from "react";
import styled from "styled-components";
import BD_Img from "../../../assets/img/bd_profil.jpg"

const AboutSection = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;

  @media screen and (max-width: 992px) {    
    .about-content {
      p {
        width: 60% !important;
      }
    }
  }
  
  
  @media screen and (max-width: 688px) {
    .about-content {
      flex-direction: column !important;

      p {
        padding-top: 30px;
        width: 100% !important;
      }
    }
  }

  .about-content {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0 20px;

    img {
      width: 200px;
      border-radius: 50%;
    }

    p {
      width: 70%;
    }
  }

  

  h2 {
    font-size: 3rem;
    color: #303e50;
    padding: 15px;
  }
  h4 {
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
  hr {
    width: 60px;
    margin: 30px auto;
    border-top: 2px solid #303e50;
  }
  p {
    color: #6c747d;
    margin: 0 auto;
    font-size: 1.1rem;
    line-height: 1.5;
    max-width: 800px;
    text-align: center;
  }
`;

function About() {
  return (
    <AboutSection id="about">
      
      
      <h4>Mein Profil</h4>
      <h2>Wer ich bin</h2>
      <hr />
      <div className="about-content"><img src={BD_Img} alt="bd"/> <p>
        Ich bin Full Stack Web Developer mit Sitz in Wien. Neben der Planung und Entwicklung von neuen Webapplikationen gehören auch die Betreuung und Erweiterung bestehender Systeme zu meinen Tätigkeiten. Bei Bedarf stehe ich ebenso in beratender Funktion zur Verfügung oder helfe beim Einstieg in Programmiersprachen, Frameworks und Content Management Systeme. Die sprichwörtlichen neuen Herausforderungen sind meine Motivation, denn ich liebe es, Probleme zu lösen. <br/><b>Ihr digitaler Erfolg ist mein Ziel.</b>
      </p></div>
     
      
    </AboutSection>
  );
}

export default About;
