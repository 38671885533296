import React from "react";
import styled from "styled-components";
import Project from "./Project";
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icons from "../../../services/fontawesome";

// Demo Images
import AirbnbDemo from "../../../assets/img/demo/responsive-demo-airbnb-clone.png"
import Covid19Demo from "../../../assets/img/demo/responsive-demo-covid19-tracker.png"
import AmazonDemo from "../../../assets/img/demo/responsive-demo-amazon-clone.png"
import ClassicArmoryDemo from "../../../assets/img/demo/responsive-demo-classic-armory.png"
import NetflixDemo from "../../../assets/img/demo/responsive-demo-netflix-clone.png"
import FacebookDemo from "../../../assets/img/demo/responsive-demo-facebook-clone.png"

const PortfolioSection = styled.div`
  padding-top: 100px;
  text-align: center;
  

  > h2 {
    font-size: 3rem;
    color: #303e50;
    padding: 15px;
  }
  > h4 {
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
  > hr {
    width: 60px;
    margin: 30px auto;
    border-top: 2px solid #303e50;
  }
  > p {
    color: #6c747d;
    margin: 0 auto;
    font-size: 1.1rem;
    line-height: 1.4;
    padding-bottom: 40px;
    max-width: 800px;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const ProjectList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Contact = styled.div`
  padding: 30px;
  text-align: center;
  background-color: #25303f;
  color: #fff;
  border-bottom: 1px solid #f3f3f3;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    line-height: 1.6rem;
    font-size: 1.1rem;
  }

  svg {
    margin-top: 20px; 
    color: #fff;
    display: block;
    height: 35px;
    width: 35px;
    font-size: 35px;
    line-height: 35px;
    color: #fff;
    border-radius: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  svg:hover {
    transform: scale(1.1);
  }
`;

function Portfolio() {
  return (
    <PortfolioSection id="portfolio">
      <h4>Einige meiner Projekte</h4>
      <h2>Was ich so mache</h2>
      <hr />
      <p>
       Ich programmiere viel und gerne - auch in meiner Freizeit. Unter diesen Beispielen befinden sich daher nicht nur Aufträge von Kunden, sondern auch Projekte, mit denen ich neue Sprachen und Frameworks gelernt oder vertieft habe.
      </p>
      <ProjectList>
        <Project
          img={AirbnbDemo}
          bg="#EEEEEE"
          title={"AirBnb Clone"}
          demo={"https://airbnb-clone-a22b0.web.app/"}
          usedReact
          usedFirebase
        />
        <Project
          img={AmazonDemo}
          bg="#e8f0f4"
          title={"Amazon Clone"}
          demo={"https://clone-4ae40.web.app/"}
          usedReact
          usedFirebase
        />
        <Project
        img={ClassicArmoryDemo}
        bg="#EEEEEE"
        title={"WoW Classic Armory"}
        usedReact
        usedNodejs
        usedMongodb
        />
        <Project
        img={Covid19Demo}
        bg="#e8f0f4"
        title={"Covid 19 Tracker"}
        demo={"https://covid-19-tracker-89903.web.app/"}
        usedReact
        usedFirebase
        />
        <Project
        img={NetflixDemo}
        bg="#EEEEEE"
        title={"Netflix Clone"}
        demo={"https://netflix-clone-1b61a.web.app/"}
        usedReact
        usedFirebase
        />
        <Project
        img={FacebookDemo}
        bg="#e8f0f4"
        title={"Facebook Clone"}
        demo={"https://facebook-clone-ce5eb.web.app/"}
        usedReact
        usedFirebase
        />
        
      </ProjectList>
      <Contact><p>Mehr über diese und weitere Projekte können Sie gerne in einem persönlichen Gespräch erfahren.<br/>Kontaktieren Sie mich einfach! </p>
      <HashLink to="/#contact" scroll={(el) => el.scrollIntoView({ behavior: 'smooth'})}>
      <FontAwesomeIcon icon={Icons.down} />
       </HashLink>
      </Contact>
    </PortfolioSection>
  );
}

export default Portfolio;
