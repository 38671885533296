import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ServiceSingle = styled.div`
  background-color: #303e50;
  color: #fff;
  padding: 35px 20px;
  text-align: center;
  margin: 15px;
  width: calc(19% - 10px);

  @media screen and (max-width: 992px) {
    width: calc(40% - 10px);
    
  }
  
  
  @media screen and (max-width: 688px) {
    width: calc(80% - 10px);
  }
  

  svg {
    height: 45px;
    width: 45px !important;
  }

  h4 {
    margin: 20px 0;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 1rem;
  }
  p {
    color: #e5e7e9;
    line-height: 1.5;
    font-size: 0.9rem;
  }
`;

function ServiceCard({ icon, title, text }) {
  return (
    <ServiceSingle>
      <FontAwesomeIcon icon={icon} />
      <h4>{title}</h4>
      <p>{text}</p>
    </ServiceSingle>
  );
}

export default ServiceCard;
