import React from "react";
import styled from "styled-components";
import { OpenImpressum } from "../../services/redux/actions";
import { useDispatch } from "react-redux";

const FooterSection = styled.div`
  background: #27343e;
  color: #ccc;
  font-size: 0.7rem;

   
  @media screen and (max-width: 688px) {
    .inner {
      flex-direction: column-reverse;
      align-items: center;
    }

    .right {
      padding-bottom: 20px;
    }
  }

  .inner {
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    padding: 15px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    max-width: 1170px;
    color: rgba(255, 255, 255, 0.5);

    .right {
      justify-self: flex-end;

      ul {
        margin: 0;
        padding: 0;
      }
      li {
        list-style: none;
        display: inline-block;
        padding: 0 10px;
        border-right: 1px solid rgba(255, 255, 255, 0.3);

        &:last-child {
          border: none;
          padding-right: 0;
        }
      }
      
      span:hover {
        text-decoration: underline;
        cursor: pointer;
      }

      a {
        color: #ccc;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

function Footer() {  
  const dispatch = useDispatch(); 

  const openImpressum = () => {    
    document.body.style.overflow = "hidden";
    dispatch(OpenImpressum());
  }
  return (
    <FooterSection>
      <div className="inner">
        <div className="left">
          © 2022 Benjamin Diener. Alle Rechte vorbehalten.
        </div>
        <div className="right">
          <ul>
            <li>              
              <span onClick={openImpressum}>Impressum</span>
            </li>
          </ul>
        </div>
      </div>
    </FooterSection>
  );
}

export default Footer;
