export const OpenMenu = () => {
  return {
    type: "MENU_OPEN",
  };
};

export const CloseMenu = () => {
  return {
    type: "MENU_CLOSE",
  };
};

export const OpenImpressum = () => {
  return {
    type: "IMPRESSUM_OPEN",
  };
};

export const CloseImpressum = () => {
  return {
    type: "IMPRESSUM_CLOSE",
  };
};
