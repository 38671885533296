import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faBars,
  faTimes,
  faLayerGroup,
  faUserGraduate,
  faBug,
  faChevronCircleDown,
  faChevronLeft,
  faChevronRight,
  faQuoteLeft,
  faQuoteRight,
  faCode,
  faLightbulb
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faXing,
  faGithubAlt,
  faLinkedinIn,
  faSkype,
} from "@fortawesome/free-brands-svg-icons";

const Icons = {
  email: faEnvelope,
  burger: faBars,
  close: faTimes,
  facebook: faFacebookF,
  twitter: faTwitter,
  linkedin: faLinkedinIn,
  xing: faXing,
  github: faGithubAlt,
  skype: faSkype,
  webdev: faLayerGroup,
  coaching: faUserGraduate,
  bug: faBug,
  down: faChevronCircleDown,
  left: faChevronLeft,
  right: faChevronRight,
  quoteRight: faQuoteRight,
  quoteLeft: faQuoteLeft,
  code: faCode,
  light: faLightbulb
};

export default Icons;
