import { combineReducers } from "redux";
import isMenuOpenReducer from "./isMenuOpen";
import isImpressumOpenReducer from "./isImpressumOpen";

const allReducers = combineReducers({
  isMenuOpen: isMenuOpenReducer,
  isImpressumOpen: isImpressumOpenReducer
});

export default allReducers;
