import React from "react";
import styled from "styled-components";
import ReactLogo from "../../../assets/img/logos/react.svg"
import ReduxLogo from "../../../assets/img/logos/redux.svg"
import FirebaseLogo from "../../../assets/img/logos/firebase.svg"
import GraphqlLogo from "../../../assets/img/logos/graphql.svg"
import JavascriptLogo from "../../../assets/img/javascript-logo.png"
import NodejsLogo from "../../../assets/img/logos/nodejs.svg"
import MongodbLogo from "../../../assets/img/logos/mongodb.svg"
import ExpressLogo from "../../../assets/img/logos/express.svg"
import HTML5Logo from "../../../assets/img/logos/html5.svg"
import CSS3Logo from "../../../assets/img/logos/css3.svg"
import PHPLogo from "../../../assets/img/logos/php.svg"
import WordpressLogo from "../../../assets/img/logos/wordpress.svg"
import PythonLogo from "../../../assets/img/logos/python.svg"
import GithubLogo from "../../../assets/img/logos/github.svg"
import BootstrapLogo from "../../../assets/img/logos/bootstrap.svg"

const SkillSection = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  //background-color: #f4f8fb;
  background-color: #fff;

  @media screen and (max-width: 992px) {    
    flex-direction: column;
    align-items: center;

    .left {
      width: 100% !important;
      padding: 0 !important;
    }

    .right {
      padding: 0 20px !important;
    }

    p {
      width: 90% !important;
     }

    li {
      width: 24% !important;
      padding: 20px 5px;
      border-bottom: 1px solid #f0f0f0 !important;
      border-right: 1px solid #f0f0f0 !important;
      text-align: center;
    }
    li:nth-child(3n) {
      border-right: none !important;
    }
    li:nth-child(n + 13) {
      border-bottom: none !important;
    }
   
  }


  .left {
    text-align: center;
    padding: 0 30px;
    width: 50%;

  }

  h3 {
    font-size: 2.5rem;
    color: #303e50;
    padding: 15px;
  }
  h4 {
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
  hr {
    width: 60px;
    margin: 30px auto;
    border-top: 2px solid #303e50;
  }
  p {
    color: #6c747d;
    margin: 0 auto;
    font-size: 1.1rem;
    line-height: 1.4;
    padding-bottom: 40px;
    width: 60%;
    text-align: center;   

  }

  .right {
    display: flex;
    flex-wrap: wrap;
    padding: 0 50px;

    @media screen and (max-width: 992px) {   
      justify-content: center;
    }
    

    li {
      list-style: none;
      width: 20%;
      padding: 20px 5px;
      border-bottom: 1px solid #f0f0f0;
      border-right: 1px solid #f0f0f0;
      text-align: center;
    }
    li:nth-child(4n) {
      border-right: none;
    }
    li:nth-child(n + 13) {
      border-bottom: none;
    }

    img {
      height: 50px;
    }

    img.wide {
      width: 60px;
    }
    span {
      display: block;
      text-transform: uppercase;
      font-size: 0.6rem;
      font-weight: 600;
      letter-spacing: 1px;
      color: rgba(108, 116, 125, 0.5)
    }
  }
`;

function Skills() {
  return (
    <SkillSection id="skills">
      <div className="left">
        <h4>Sprachen, Frameworks, CMS & Co</h4>
        <h3>Womit ich Erfahrung habe</h3>
        <hr />
        <p>
        Als Full Stack Developer bin ich mit vielen verschiedenen Tools und Technologien vertraut. Kontinuierliche Weiterbildung ist ein wichtiger Bestandteil, um Projekte effizient und sicher umsetzen zu können. 
        </p>
      </div>
      <ul className="right">
        <li>
          <img
            alt="React"
            src={ReactLogo}
          />
          <span>React</span>
        </li>
        <li>
          <img
            alt="Redux"
            src={ReduxLogo}
          />
          <span>Redux</span>
        </li>
        <li>
          <img
            alt="GraphQL"
            src={GraphqlLogo}
          />
          <span>GraphQl</span>
        </li>
        <li>
          <img
            alt="Firebase"
            src={FirebaseLogo}
          />
          <span>Firebase</span>
        </li>
        <li>
          <img
            alt="NodeJS"
            src={NodejsLogo}
          />
           <span>Nodejs</span>
        </li>
        <li>
          <img
            alt="MongoDB"
            src={MongodbLogo}
          />
          <span>Mongodb</span>
        </li>
        <li>
          <img
            className="wide"
            alt="Express"
            src={ExpressLogo}
          />
          <span>Express</span>
        </li>
        <li>
          <img            
            alt="Bootstrap"
            src={BootstrapLogo}
          />
          <span>Bootstrap</span>
        </li>
        <li>
          <img            
            alt="HTML"
            src={HTML5Logo}
          />
          <span>HTML</span>
        </li>
        <li>
          <img
            alt="CSS"
            src={CSS3Logo}
          />
          <span>CSS</span>
        </li>          
        <li>
          <img
            alt="JavaScript"
            src={JavascriptLogo}
          />
          <span>JavaScript</span>
        </li> 
        <li>
          <img
            alt="PHP"
            src={PHPLogo}
          />
          <span>PHP</span>
        </li> 
        <li>
          <img
            alt="Wordpress"
            src={WordpressLogo}
          />
          <span>Wordpress</span>
        </li> 
        <li>
          <img
            alt="Python"
            src={PythonLogo}
          />
          <span>Python</span>
        </li> 
        <li>
          <img
          className="wide"
            alt="Github"
            src={GithubLogo}
          />
          <span>Github</span>
        </li> 
      </ul>
    </SkillSection>
  );
}

export default Skills;
