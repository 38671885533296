import "../css/App.css";
import Home from "./content/Home";
import About from "./content/About";
import Services from "./content/Services";
import Portfolio from "./content/Portfolio";
import Skills from "./content/Skills";
import Clients from "./content/Clients";
import Contact from "./content/Contact";
import Footer from "./layout/Footer";
import useIsScrolled from "./utils/useIsScrolled";
import StickyHeader from "./layout/StickyHeader";
import { useSelector } from "react-redux";
import DropdownMenu from "./layout/DropdownMenu";
import Impressum from "./content/Impressum";

function App() {
  const isMenuOpen = useSelector((state) => state.isMenuOpen);
  const isImpressumOpen = useSelector((state) => state.isImpressumOpen);
  const scrolled = useIsScrolled();
  return (
    <div className="app">
      {isMenuOpen && <DropdownMenu />}
      {scrolled && !isMenuOpen && <StickyHeader />}
      {isImpressumOpen && <Impressum/>}
      <Home />
      <About />
      <Services />
      <Portfolio />
      <Skills />       
      <Clients />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;

// http://themes.hody.co/comet/
// http://themes.hody.co/comet/home/resume/
// https://preview.themeforest.net/item/alecta-a-smart-theme-for-creative-agencies-and-freelancers/full_screen_preview/20304098?_ga=2.240901642.803995859.1499939417-500227559.1499939417
// https://alecta.qodeinteractive.com/parallax-showcase/
// http://preview.themeforest.net/item/comet-creative-multipurpose-wordpress-theme/full_screen_preview/15163936?_ga=2.57247971.850441179.1615293267-1932816734.1573119307
// https://qodeinteractive.com/wordpress-theme/wonderment-agency-theme/?utm_campaign=select&utm_source=toolbar&_ga=2.240091463.574863862.1615298244-1111618844.1615298244
// http://preview.themeforest.net/item/exclaire-personal-development-coach-wordpress-theme/full_screen_preview/17366270?_ga=2.15395507.488318398.1615560986-1932816734.1573119307
