const isImpressumOpenReducer = (state = false, action) => {
    switch (action.type) {
      case "IMPRESSUM_OPEN":
        return true;
      case "IMPRESSUM_CLOSE":
        return false;
      default:
        return false;
    }
  };
  
  export default isImpressumOpenReducer;
  