import { useState, useEffect } from "react";

const useIsScrolled = () => {
  const [show, setShow] = useState(false);

  const handleScroll = () => {
    const top = window.pageYOffset || document.documentElement.scrollTop;
    setShow(top >= 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return show;
};

export default useIsScrolled;
