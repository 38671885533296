import React from "react";
import "../../../css/Home.css";
import Header from "../../layout/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icons from "../../../services/fontawesome";
import SocialIcon from "./SocialIcon";
import { HashLink } from 'react-router-hash-link';

function Home() {
  return (
    <div className="home" id="home">
      <div className="home__overlay">
        <div className="home__header">
          <Header />
        </div>
        <div className="home__hero">               
          
          <h2>Full Stack Web</h2>
          <h1>Developer</h1>
          <ul className="home__socials">
            <SocialIcon icon={Icons.facebook} color="#1877f2" url="https://www.facebook.com/benjamindiener"/>
            <SocialIcon icon={Icons.xing} color="#026466" url="https://www.xing.com/profile/Benjamin_Diener3"/>
            <SocialIcon icon={Icons.github} color="#24292e" url="https://github.com/bnidev"/>
            <SocialIcon icon={Icons.email} color="#0072c6" url="mailto:bd@benjamindiener.com"/>
            <SocialIcon icon={Icons.skype} color="#00aff0" url="skype:mousesports-benni?chat"/>
          </ul>
        </div>
        <HashLink className="home__scrolldown" to="/#about" scroll={(el) => el.scrollIntoView({ behavior: 'smooth'})}>
          <FontAwesomeIcon icon={Icons.down} />
        </HashLink>
      </div>
    </div>
  );
}

export default Home;
