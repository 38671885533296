import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icons from "../../../services/fontawesome";
import styled from "styled-components";

const ListItem = styled.li`
  display: inline-block;
  margin: 5px;
  text-decoration: none;

  a {
    display: block;
    width: 42px;
    height: 42px;
    line-height: 42px;
    color: #fff;
    background-color: rgba(255, 255, 255, 0.1);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    text-align: center;
    border-radius: 50%;

    &:hover {
      cursor: pointer;
      background: ${({ color }) =>
        color ? color : "rgba(255, 255, 255, 0.3)"};
    }
  }
`;

function SocialIcon({ icon, url, color }) {
  return (
    <ListItem color={color}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={icon} />
      </a>
    </ListItem>
  );
}

export default SocialIcon;
