import React from "react";
import styled from "styled-components";

// Logos
import ReactLogo from "../../../assets/img/logos/react.svg"
import ReduxLogo from "../../../assets/img/logos/redux.svg"
import FirebaseLogo from "../../../assets/img/logos/firebase.svg"
import GraphqlLogo from "../../../assets/img/logos/graphql.svg"
import JavascriptLogo from "../../../assets/img/javascript-logo.png"
import NodejsLogo from "../../../assets/img/logos/nodejs.svg"
import MongodbLogo from "../../../assets/img/logos/mongodb.svg"
import ExpressLogo from "../../../assets/img/logos/express.svg"
import HTML5Logo from "../../../assets/img/logos/html5.svg"
import CSS3Logo from "../../../assets/img/logos/css3.svg"
import PHPLogo from "../../../assets/img/logos/php.svg"
import WordpressLogo from "../../../assets/img/logos/wordpress.svg"
import PythonLogo from "../../../assets/img/logos/python.svg"
import GithubLogo from "../../../assets/img/logos/github.svg"
import BootstrapLogo from "../../../assets/img/logos/bootstrap.svg"

const BuildInfo = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(33, 40, 45, 0.7);
  transition: all 0.5s ease;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    h4 {
      font-size: 2.2rem;
    }

    h5 {
      font-size: 1.1rem;
      font-weight: normal;
    }

    ul {
      padding: 0;
      margin: 15px 0;
      display: flex;
    }

    li {
      list-style: none;
      padding-left: 10px;
      padding-right: 10px;
    }

    img {
      height: 35px;
    }

    a {      
      color: rgba(255,255,255,0.8);
      text-decoration: none;
      margin-top: 15px;
      border: 1px solid rgba(255,255,255,0.8);   
      border-radius: 0.2rem;   
      padding: 7px 12px 10px 12px;
      font-size: 1rem
    }
    a:hover {
      color: rgba(0,0,0,0.8);
      border-color: #fff;
      background-color: #fff;
      transition: all 0.5s ease;
    }
  }

  @media screen and (max-width: 992px) and (min-width: 688px) {   
    .content h4 {
      font-size: 1.2rem;
    }

    .content a {
      font-size: 0.7rem;
    }
  }

`;

const BuildImage = styled.div`
  
  img {
    width: 100%;
  }
`;

const ProjectSingle = styled.div`
  position: relative;
  overflow: hidden;
  background: ${({ bgColor }) => bgColor};
  //background: radial-gradient(circle, rgba(245,246,250,1) 0%, rgba(245,244,240,1) 43%, rgba(217,214,208,1) 100%); 
  width: 33.33%;
  &:hover ${BuildInfo} {
    opacity: 1;
  }
  &:hover ${BuildImage} {
    transform: scale(1.07);
    transition: all 0.5s ease;    
  }

  @media screen and (max-width: 688px) {
    width: 100%;
  }
`;

function Project({ title, bg, img, demo, usedReact, usedRedux, usedFirebase, usedMongodb, usedNodejs }) {
  return (
    <ProjectSingle bgColor={bg}>
      <BuildImage> 
        <img src={img} alt={title} />
      </BuildImage>
      <BuildInfo>
        <div className="content">
          <h4>{title}</h4>
          <h5>WEB APP</h5>
          <ul>
            {usedReact && <li><img src={ReactLogo} alt="React"/></li>}            
            {usedRedux && <li><img src={ReduxLogo} alt="Redux"/></li>}
            {usedFirebase && <li><img src={FirebaseLogo} alt="Firebase"/></li>}
            {usedNodejs && <li><img src={NodejsLogo} alt="NodeJS"/></li>}
            {usedMongodb && <li><img src={MongodbLogo} alt="MongoDB"/></li>}
          </ul>
          {demo && <a href={demo} target="_blank" rel="noopener noreferrer">jetzt ansehen</a>}
        </div>
      </BuildInfo>
    </ProjectSingle>
  );
}

export default Project;
