import React from "react";
import styled from "styled-components";
import logo from "../../../assets/img/logo.png";
import logo1 from "../../../assets/img/bd_logo_circle.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Icons from "../../../services/fontawesome";
import { useSelector, useDispatch } from "react-redux";
import { OpenMenu, CloseMenu, OpenImpressum } from "../../../services/redux/actions";
import SocialIcon from "./SocialIcon";
import { HashLink } from 'react-router-hash-link';

const Dropdown = styled.div`
  background-color: #fff;
  height: 100vh;
  z-index: 50;
  position: fixed;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 992px) {
    .dropdownMenu__content ul li h1 {
      font-size: 1.7rem !important;
    }

    .dropdownMenu__content ul li h5 {
      font-size: 0.7rem !important;
    }
  }
    
  @media screen and (max-width: 688px) {
    
  }

  .dropdownMenu__header {
  }

  .dropdownMenu__wrapper {
    padding: 10px 30px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dropdownMenu__left > img {
    height: 30px;
  }

  .dropdownMenu__right > svg {
    font-size: 1.5rem;
  }

  .dropdownMenu__right {
    cursor: pointer;
  }

  .dropdownMenu__content {
    ul {
      padding: 0;
      margin-top: 50px;
      margin-bottom: 50px;
      li {
        padding: 15px;
        text-align: center;
        list-style: none;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        animation: fade-in ease-in-out 0.8s;
        animation-fill-mode: forwards;
        opacity: 0;

        @media screen and (max-width: 688px) {
          padding: 10px !important;
        }

        &:hover {
          font-size: 2rem;
        }

        &:nth-child(1) {
          animation-delay: 0s;
        }
        &:nth-child(2) {
          animation-delay: 0.2s;
        }
        &:nth-child(3) {
          animation-delay: 0.4s;
        }
        &:nth-child(4) {
          animation-delay: 0.6s;
        }
        &:nth-child(5) {
          animation-delay: 0.8s;
        }
        &:nth-child(6) {
          animation-delay: 1s;
        }
        &:nth-child(7) {
          animation-delay: 1.2s;
        }

        a {
          text-decoration: none;
        }

        h1 {
          color: #303e50;
        }

        h5 {
          text-transform: uppercase;
          font-weight: 400;
          letter-spacing: 1.4px;
          color: #909090;
        }
      }
    }
  }

  .dropdownMenu__footer {
    padding: 10px;
    .dropdownMenu__wrapper {
      border-top: 1px solid #e8e8e8;
      font-size: 0.9rem;
      margin: 0 auto;
      color: rgba(168, 168, 168, 0.8);
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 688px) {
        flex-direction: column;
      }

      .left {
        ul {
          padding: 0;
        }
        @media screen and (max-width: 688px) {
          flex-direction: column;
        }
        margin: 15px 0;
      }

      .right {
        justify-self: flex-end;

        ul {
          margin: 0;
          padding: 0;
        }
        li {
          list-style: none;
          display: inline-block;
          padding: 0 10px;
          border-right: 1px solid rgba(168, 168, 168, 0.5);

          &:last-child {
            border: none;
            padding-right: 0;
          }
        }

        span:hover {
          text-decoration: underline;
          cursor: pointer;
        }

        a {
          color: rgba(168, 168, 168, 0.8);
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
      transform: scale(0.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

function DropdownMenu() {
  const isMenuOpen = useSelector((state) => state.isMenuOpen);
  const dispatch = useDispatch();

  const toggleMenu = () => {
    if (isMenuOpen) {
      document.body.style.overflow = "";
      dispatch(CloseMenu());
    } else {
      document.body.style.overflow = "hidden";
      dispatch(OpenMenu());
    }
  };

  const openImpressum = () => {
    dispatch(CloseMenu());
    document.body.style.overflow = "hidden";
    dispatch(OpenImpressum());
  }

  return (
    <Dropdown>
      <div className="dropdownMenu__header">
        <div className="dropdownMenu__wrapper">
          <div className="dropdownMenu__left">
            <img src={logo1} alt="logo" />
            <img src={logo} alt="logo" />
          </div>
          <div className="dropdownMenu__right">
            <FontAwesomeIcon
              icon={isMenuOpen ? Icons.close : Icons.burger}
              onClick={() => toggleMenu()}
            />
          </div>
        </div>
      </div>
      <div className="dropdownMenu__content">
        <ul>
          <li>
            <HashLink to="/#home" scroll={(el) => el.scrollIntoView({ behavior: 'smooth'})} onClick={toggleMenu}>
              <h1>Hallo!</h1>
              <h5>Zurück zum Start</h5>
            </HashLink>
          </li>
          <li>
            <HashLink to="/#about" scroll={(el) => el.scrollIntoView({ behavior: 'smooth'})} onClick={toggleMenu}>
              <h1>Über mich</h1>
              <h5>Wer ich bin</h5>
            </HashLink>
          </li>
          <li>
            <HashLink to="/#services" scroll={(el) => el.scrollIntoView({ behavior: 'smooth'})} onClick={toggleMenu}>
              <h1>Leistungen</h1>
              <h5>Was ich anbiete</h5>
            </HashLink>
          </li>
          <li>
            <HashLink to="/#portfolio" scroll={(el) => el.scrollIntoView({ behavior: 'smooth'})} onClick={toggleMenu}>
              <h1>Portfolio</h1>
              <h5>Einblick in meine Arbeit</h5>
            </HashLink>
          </li>
          <li>
            <HashLink to="/#skills" scroll={(el) => el.scrollIntoView({ behavior: 'smooth'})} onClick={toggleMenu}>
              <h1>Skills</h1>
              <h5>Meine Erfahrung</h5>
            </HashLink>
          </li>
          
          <li>
            <HashLink to="/#clients" scroll={(el) => el.scrollIntoView({ behavior: 'smooth'})} onClick={toggleMenu}>
              <h1>Kunden</h1>
              <h5>Erfolgreiche Projekte</h5>
            </HashLink>
          </li>
        </ul>
      </div>
      <div className="dropdownMenu__footer">
        <div className="dropdownMenu__wrapper">
          <div className="left">
            <ul className="socials">
              <SocialIcon icon={Icons.facebook} color="#1877f2" url="https://www.facebook.com/benjamindiener"/>
              <SocialIcon icon={Icons.twitter} color="#1da1f2" url="https://twitter.com/benjamindiener"/>
              <SocialIcon icon={Icons.xing} color="#026466" url="https://www.xing.com/profile/Benjamin_Diener3"/>
              <SocialIcon icon={Icons.github} color="#24292e" url="https://github.com/bnidev"/>
              <SocialIcon icon={Icons.email} color="#0072c6" url="mailto:bd@benjamindiener.com"/>
              <SocialIcon icon={Icons.skype} color="#00aff0" url="skype:mousesports-benni?chat"/>
            </ul>
          </div>
          <div className="right">
            <ul>
              <li>
              <span onClick={openImpressum}>Impressum</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Dropdown>
  );
}

export default DropdownMenu;
