import React from "react";
import styled from "styled-components";
import Icons from "../../../services/fontawesome";
import ServiceCard from "./ServiceCard";

const ServicesSection = styled.div`
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #25303f;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;  

  > h2 {
    font-size: 3rem;
    text-align: center;
    padding: 15px;
  }
  > h4 {
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    padding: 0 15px;
  }
  > hr {
    width: 60px;
    margin: 30px auto;
    border-top: 2px solid #fff;
  }
  > p {
    color: #6c747d;
    margin: 0 auto;
    font-size: 1.1rem;
    line-height: 1.4;
  }
`;

const ServicesList = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;  
  
`;

function Services() {
  return (
    <ServicesSection id="services">
      <h4>Meine Arbeit</h4>
      <h2>Was ich anbiete</h2>
      <hr />
      <ServicesList>
      <ServiceCard
          title="Planung"
          icon={Icons.light}
          text="Ich erstelle aus Ihrer Idee ein passendes Konzept, das als strukturelle Grundlage für die Entwicklug dient."
        />
        <ServiceCard
          title="Entwicklung"
          icon={Icons.code}
          text="Ich entwickle maßgeschneiderte Tools, Features oder komplette Webapplikationen ganz nach Ihrem Bedarf."
        />
        <ServiceCard
          title="Beratung & Schulung"
          icon={Icons.coaching}
          text="Ich berate Sie in digitalen Fragen und helfe Ihnen beim Einstieg in neue Technologien, Sprachen und Frameworks."
        />
        <ServiceCard
          title="Projektmanagement"
          icon={Icons.bug}
          text="Ich leite Ihr Web-Projekt und koordiniere alle damit verbundenen Tätigkeiten und Personen."
        />
        
      </ServicesList>
    </ServicesSection>
  );
}

export default Services;
