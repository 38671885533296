import React from "react";
import styled from "styled-components";

const Client = styled.div`
  background: #fff;
  border: 1px solid #e9eef2;
  margin: 10px;
  width: 15%;

  @media screen and (max-width: 992px) {    
    width: 21% !important;
  }
  
  @media screen and (max-width: 688px) {
    width: 40% !important;
  }

  img {
    width: 100%;
  }

  &:hover {
    border-color: #c9cdd1;
  }
`;

function ClientLogo({ name, img }) {
  return (
    <Client>
      <img src={img} alt={name} />
    </Client>
  );
}

export default ClientLogo;
